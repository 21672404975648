<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <router-link tag="a" :to="{name:'Main'}" class="navbar-brand" style="margin-top:-2px;z-index:1000"><img src="/images/landing/header-chip-32.png" height="32"></router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">

        <li class="nav-item">
          <router-link tag="a" :to="{name:'Documentation'}" class="nav-link bg-light" :class="{'active':nav==1}">Products</router-link>
        </li> 

        <li class="nav-item">
          <router-link tag="a" :to="{name:'DocumentationApi'}" class="nav-link bg-light" :class="{'active':nav==2}"> Solutions </router-link>
        </li>  

        <!-- 
        <li class="nav-item">
          <router-link tag="a" :to="{name:'Pricing'}" class="nav-link bg-light" :class="{'active':nav==3}"> Pricing </router-link>
        </li>  

         <li class="nav-item">
          <router-link tag="a" :to="{name:'DfmDemo'}" class="nav-link" :class="{'active':nav==6}">DFM Demo</router-link>
        </li>
        <li class="nav-item">
          <router-link tag="a" :to="{name:'Demo'}" class="nav-link" :class="{'active':nav==5}">API Demo</router-link>
        </li>  -->     
        
        <li class="nav-item">
          <a href="https://www.numericalinnovations.com/blogs/news" target="preflightpcb_blog" class="nav-link bg-light">Blog</a>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li v-if="$store.state.session.user==null" class="nav-item">
            <router-link tag="a" :to="{name:'Signup'}" class="nav-link" :class="{'active':nav==4}">Get started for free</router-link>
          </li>
          <li class="nav-item">
            <a href="javascript:;" class="nav-link bg-light" :class="{'active':nav==5}" @click="loginClicked">{{$store.state.session.user!=null ? 'Dashboard' : 'Login'}}</a>
          </li>
        </ul>
      </form>
    </div>
  </nav>
</template>

<script>
  import Common from '../mixins/Common'

  export default {

    props: ['nav'],

    mixins: [Common],

    methods: {

      loginClicked() {
        if(this.$store.state.session.user!=null) {
          window.location = '/admin';
          return;
        }
        else this.$router.push({name:'Login'})
      }

    }

  }
</script>
