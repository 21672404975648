<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="2"></page-header>
      <!-- Navigation -->

      <div class="container mt-5 mb-5">

        <h2 class='mb-3'>PCBPreflight Pricing</h2>
        <h5 class='mb-5 subtitle'>
          UNDER CONSTRUCTION
        </h5>

        <!--  <div class="pricing card-deck flex-column flex-md-row mb-0">

          <div class="card card-pricing text-center shadow-sm mb-4">
            <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm"> FREE (Hobby)</span>
            <div class="bg-transparent card-header pt-4 border-0">
              <h3 class="h3 font-weight-normal text-primary text-center mb-0">$<span class="price">0.00</span><span class="h6 text-muted ml-2">/ per minute</span></h3>
            </div>
            <div class="card-body pt-0 px-2">
              <ul class="list-unstyled mb-2">
                <li>All PCBPreflight Features <i class="fa fa-question-circle text-muted ml-1" v-b-tooltip.hover.top title="Get access to all PCBPreflight features: Import, Export, DFM & Netlist Check, Compare Layer/Job, and more. Does not include CAM Package Add-On."></i></li>
                <li>Unlimited File Size!</li>
                <li>100 Free Minutes per Month</li>
                <li>Free Technical Support</li>
                <li>Perfect for Hobbyist and Educational</li>
                <li>Perfect for Sandbox Testing</li>
             </ul>
            </div>
            <div class="card-footer">
              <button type="button" class="btn btn-outline-secondary" @click="changePlan('hobby')">Get Started</button>
            </div>
          </div>

          <div class="card card-pricing shadow-sm text-center mb-4">
            <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">PAID (Flat Rate)</span>
            <div class="bg-transparent card-header pt-4 border-0">
              <h3 class="h3 font-weight-normal text-primary text-center mb-0">$<span class="price">0.16</span><span class="h6 text-muted ml-2">/ per minute</span></h3>
            </div>
            <div class="card-body pt-0 px-2">
              <ul class="list-unstyled mb-2">
                <li>All PCBPreflight Features <i class="fa fa-question-circle text-muted ml-1" v-b-tooltip.hover.top title="Get access to all PCBPreflight features: Import, Export, DFM & Netlist Check, Compare Layer/Job, and more. Does not include CAM Package Add-On."></i></li>
                <li>Unlimited Minutes available!</li>
                <li>Unlimited Concurrent Jobs</li>
                <li>High-Priority Job processing</li>
                <li>Priority Technical Support</li>
                <li>Perfect for Commercial Use</li>
             </ul>
            </div>
            <div class="card-footer">
              <button type="button" class="btn btn-outline-primary" @click="changePlan('standard')">Get Started</button>
            </div>
          </div>

        </div>

        <div class="card card-pricing shadow-sm mb-5 mt-0" style="overflow:hidden">
          <div class="d-flex">
            <div style="width:16px;" class="bg-primary">
            </div>
            <div style="color:#6c757d" class="px-4 py-4 flex-grow-1">
              <div class="mb-3"><b>CAM Package Add-On</b> - <span class="text-primary h5">$250</span> / per month</div>
              <span>Transform PCBPreflight into an automated CAM/Gerber powerhouse for PCB manufacturing. Includes full-featured Editing, PCB Panelization and more. Export results to popular EDA formats: Gerber, ODB++, DXF, NC Drill, NC rout, and more. This Add-On is optional and not available with free HOBBY accounts.</span>
            </div>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col">
            <p><h5>What is a job minute?</h5></p>
            <p class="lead">The longer a job takes to complete, the more resource consuming and expensive it is. Every job takes at least one minute. Normally, most jobs consume only one minute. Of course, only successful jobs are counted. You can check your consumed job minutes, to get an idea about it.</p>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col">
            <p><h5>Why do I have to pay for a full minute?</h5></p>
            <p class="lead">80% of the jobs take less than a minute. Our pricing model is that every job taking < 1 minute costs the same. By this you can be sure that your jobs always cost the same.</p>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col">
            <p><h5>Free evaluation for testing?</h5></p>
            <p class="lead">Get a full-featured trial of PCB Preflight for testing purposes.  Please <router-link tag="a" :to="{name:'Contact'}">contact</router-link> us directly and tell us what specific features you're interested in - or problems you wish to solve.  A valid Company email address is required for trial entitlement.</p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p><h5>Further Questions?</h5></p>
            <p class="lead">Do you have any additional questions? Are you a large volume customer who would like to negotiate an individual agreement? Feel free to <router-link tag="a" :to="{name:'Contact'}">contact</router-link> us directly and we'll be happy to assist you further.</p>
          </div>
        </div> -->

      </div>
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<style>
.subtitle {
  line-height:1.5em;
  color: #6c757d;
}
.card-pricing {
  z-index: 1;
  border: 2px solid rgba(0,0,0,0.1);
  border-radius: 16px;
}
.card-pricing.popular {
  border: 2px solid #007bff;
}
.card-pricing .list-unstyled li {
  padding: .4rem 0;
  color: #6c757d;
}
</style>


<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  export default {

    mixins: [Common],

    components: {
      PageHeader,
      PageFooter,
      VueSlider
    },

    data() {
      return {
        money: new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}),
        package_mins: 100,
        package_cost: 0.30
      }
    },

    mounted() {
      app = this;
      document.title = 'PCBPreflight Pricing';
    },

    methods: {

      changePlan(plan) {
        this.$router.push({name:'Signup'});
      }

    }

  }
</script>
