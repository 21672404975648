<template>
    <main role="main" class="d-flex flex-column" style="min-height:100vh">
        <div style="flex:1 1 auto">
        
            <!-- Navigation -->
            <page-header nav="2"></page-header>
    
            <!-- Body -->
            <div class="container my-3">
                <div class="row">
                    <div class="col lead">
                        
                        <h4 class="my-4 text-center">Online Visualizer: PCB Design Analysis and Collaboration </h4>
                        
                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Free Online Viewing of Gerber, Drill, ODB++, IPC-2581, FAB 3000 and more.</h5>
                        <p>PCB Preflight includes a leading cloud-based Viewer and Editing environment for electronic designs.  Meet up with Unlimited Collaborators.</p>
    
                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Easy DFM/DFA Anlysis and Collaboration</h5>
                        <p>Perform critical PCB manufacturing checks most commonly known to cause yield problems and delays during PCB fabrication. Includes powerful Design for Assembly checks, SMT Stencil checks, plus other Advanced DFM Checks: Copper Slivers, Acid Traps, Find Antennas and more.</p>
    
                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Accurate Browsing of DFM Violations</h5>
                        <p>Pinpoint DFM Results and describe violations.  Select any DFM violation and instantly highlight all similiar DFM violations.</p>  
    
                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Netlist Comparison: Find Shorts and Open Nets</h5>
                        <p>Pinpoint any Open or Shorted Nets</p>

                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Visually Compare PCB Design Differences</h5>
                        <p>Easily find all differences between similiar PCB Design revisions.</p>

                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Extract all PCB Fabrication and Assembly dimensions</h5>
                        <p> Automatically extract all PCB design data required to quote and price potential PCB manufacturing costs.</p>


                        <hr class="my-3"/>
                        <h4 class="my-4 text-center">REST API: Build PCB Manufacturing Apps within 60 Minutes or less!</h4>
            
                        <h5 class="mt-4 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Why should I care about PCB Preflight API?</h5>
                        <p>Think of <b>PCB Preflight API</b> like the food delivery service <a href="https://www.ubereats.com/" target="_blank">Uber Eats</a>, and you have a hungry family to feed waiting at home.  You could spend the time to buy groceries, drive home, prepare/cook the food, serve meals to everyone, and wash dishes.  Or you could request to have prepared meals delivered to your front door.</p>
                        <p><b>PCB Preflight API</b> waits for your <b>requests</b>, and delivers the PCB information in a single <a href="https://en.wikipedia.org/wiki/JSON" target="_blank">JSON file</a>.</p>

                        <h5 class="mt-4 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> How Difficult is the PCB Preflight API to learn?</h5>
                        <p>Very Easy.  PCB Preflight uses a revolutionary yet simple approach for building powerful online PCB manufacturing applications. Our goal was to make the PCB Preflight API so easy, anyone without web experience could - <span class="text-success"><b><i>Build an API Solution within 60 Minutes!</i></b></span></p>
            
                        <h5 class="mt-4 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Can I use the PCB Preflight API for Free? </h5>
                        <p>Yes.  All PCB Preflight plans permit API usage, including the free plan!  Depending on which PCB Preflight plan is chosen, will determine the available API features and usage limits.</p>
            
                        <h5 class="mt-4 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Do you offer a Free API Sandbox? </h5>
                        <p>Yes.  The PCB Preflight API sandbox includes sample test files that permit developers to freely test and perform simulated responses.</p>

                        <hr class="my-3"/>
                        <h5 class="mt-4 text-info">Need to Test and Visualize PCB Matrix Files on your desktop?</h5>
                        <p>To help with PCB matrix testing and visualization, try using <a href="https://www.numericalinnovations.com/collections/fab-3000-gerber-cam">FAB 3000 (Gerber/CAM)</a>.</p>
            
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <page-footer></page-footer>
        </div>

    </main>
</template>
  
  <script>
  import Common from '../mixins/Common';
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  
  export default {
  
    mixins: [Common],
  
    components: {PageHeader,PageFooter},
  
    mounted() {
        /*
      document.body.style.overflow = "auto";
      this.$store.commit('menu_id',39);
      document.title = 'API Help Guide';
      this.updateCrumbText(document.title) ;
      */
      app = this;
      document.title = 'API Help Guide';

    },
  
  }
  </script>
  