<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">
      
      <!-- Navigation -->
      <page-header nav="1"></page-header>

        <!-- Body -->
        <div class="container my-5">
          <div class="row">
            <div class="col lead">

              <p class="lead mb-3"><span class="font-weight-bold text-info">PCB Preflight</span> makes it easy to verify all PCB designs are ready for manufacturing and it's <b>available everywhere!</b> PC (Windows, Linux, Apple), iOS (iPhone, iPads) and Android devices. Adding PCB Preflight to your design flow, promotes team collaboration with your colleagues and seamless design information exchange with PCB manufacturers.</p>            
              <p  class="mt-4 mb-1">PCB Preflight has two primary products: <b>Visualize PCB</b> and <b>REST API</b></p>
              <ul>
                <li><b>Visualize PCB</b> (Design Analysis + Collaborate)</li>
                <li><b>REST API</b> (easy to build PCB Manufacturing apps)</li>
              </ul>              
              <hr class="my-4"/>
              <h5 class="mt-2">Ready to get Started Free?</h5>
              <p>First you'll need to <a href="/landing/signup">Signup</a> and create a FREE account.<br/><i class="fas fa-fw fa-columns text-primary"></i> Check out the <a href="/landing/pricing">PCBPreflight Plans</a> to compare all features and pricing.</p>

            </div>
          </div>
        </div>

      <!-- Footer -->
      <page-footer></page-footer>

    </div>

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';

  export default {

    components: {PageHeader,PageFooter},

    mounted() {
      app = this;
      document.title = 'Documentation';
    }

  }
</script>
